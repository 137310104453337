import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, IconButton } from "@mui/material";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Integer = ({
  field,
  fieldName,
  values,
  fieldErrors,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const fieldRef = useRef(null);
  useEffect(() => {
    // Imposta il valore iniziale del campo
    if (values && values[fieldName] !== undefined) {
      setInputValue(values[fieldName]);
    }
  }, [values, fieldName]);
  const handleInputChange = (e) => {
    onChange(field.fieldName, e.target.value);
  };

  useEffect(() => {
    if (fieldErrors && fieldRef.current) {
      fieldRef.current.querySelector("input").style.border = "2px solid red";
    }
  }, [fieldErrors]);

  return (
    <TextField
      type="number"
      id={fieldName}
      sx={{ height: "20px" }}
      name={fieldName}
      defaultValue={value}
      onChange={handleInputChange}
      margin="normal"
      fullWidth
      helperText={fieldErrors || ""}
    />
  );
};

export default Integer;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DashboardIcon from "@mui/icons-material/Dashboard";
import * as Icons from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { Home } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const SidebarMenu = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      try {
        const response = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/auth/getSidebar",
          { headers: headers }
        );
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchMenuItems();
  }, []);

  const iconComponents = Icons;

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const Icon =
        item.icon && Icons[item.icon] ? Icons[item.icon] : CircleIcon;

      // Rendering dell'icona basata sulla stringa JSON
      const IconToRender = iconComponents[item.icon];

      if (item.children && item.children.length > 0) {
        return (
          <Accordion
            key={item.id}
            disableGutters
            elevation={0}
            square
            sx={{ bgcolor: "#0a97d5", borderBottom: "1.2px solid #0b75a4" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {IconToRender
                ? React.createElement(IconToRender, {
                    sx: {
                      fontSize: "25px",
                      marginRight: "10px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginRight: "30px",
                    },
                  })
                : null}
              <Typography color="white">{item.label}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white", p: 0 }}>
              <List component="div" disablePadding>
                {renderMenuItems(item.children)}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        const isExternal = item.type == "externalButton";
        if (isExternal) {
          return (
            <a
              href={item.path || "#"}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {IconToRender
                    ? React.createElement(IconToRender, {
                        sx: {
                          fontSize: "25px",
                          marginRight: "10px",
                          color: "white",
                        },
                      })
                    : null}
                  <Typography sx={{ pl: 0, color: "white" }}>
                    {item.label}
                  </Typography>
                  <OpenInNewIcon
                    sx={{
                      ml: 2,
                      fontSize: 15,
                      marginRight: "10px",
                      color: "white",
                    }}
                  />
                </Box>
              </ListItemButton>
            </a>
          );
        } else {
          // If the link is internal
          return (
            <ListItemButton component={Link} to={item.relatedObject || "#"}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowForwardIosIcon sx={{ fontSize: 10 }} />
                <Typography sx={{ pl: 1 }}>{item.label}</Typography>
              </Box>
            </ListItemButton>
          );
        }
      }
    });
  };

  return <List sx={{ color: "#ffffff" }}>{renderMenuItems(menuItems)}</List>;
};

export default SidebarMenu;

import React from "react";
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  Typography,
  Button,
  Stack,
  CardHeader,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import String from "./customInputs/String";
import Address from "./customInputs/Address";
import AutocompleteMultipleLookup from "./customInputs/AutocompleteMultipleLookup";
import Integer from "./customInputs/Integer";
import Date from "./customInputs/Date";
import Datetime from "./customInputs/Datetime";
import Float from "./customInputs/Float";
import File from "./customInputs/File";
import Valuta from "./customInputs/Valuta";
import CheckboxCST from "./customInputs/CheckboxCST";
import SelectCST from "./customInputs/SelectCST";
import AutocompleteJSON from "./customInputs/AutocompleteJSON";
import Textarea from "./customInputs/Textarea";
import LocalizationInputs from "./customInputs/LocalizationInputs";
import { useSelector } from "react-redux";
import { thunks } from "../thunks/general";

const FieldsRenderer = ({ field, value, onChange, prefilledValues }) => {
  const general = useSelector((state) => state.general);

  const handleInputChange = (fieldName, newValue) => {
    fieldName = fieldName || field.name || field.fieldName;
    onChange(fieldName, newValue);
  };

  const handleAddressChange = (fieldName, newValue) => {
    fieldName = fieldName || field.name || field.fieldName;

    onChange(fieldName, newValue);
  };

  switch (field.type) {
    case "Text":
      return (
        <String field={field} value={value} onChange={handleInputChange} />
      );
    case "Address":
      return (
        <Address
          field={field}
          value={value}
          onChange={handleAddressChange}
          prefilledValues={{}}
        />
      );
    case "Email":
      return (
        <String field={field} value={value} onChange={handleInputChange} />
      );
    case "Integer":
      return (
        <Integer field={field} value={value} onChange={handleInputChange} />
      );
    case "Float":
      return <Float field={field} value={value} onChange={handleInputChange} />;
    case "Valuta":
      return (
        <Valuta field={field} value={value} onChange={handleInputChange} />
      );
    case "Date":
      return <Date field={field} value={value} onChange={handleInputChange} />;
    case "Datetime":
      return (
        <Datetime field={field} value={value} onChange={handleInputChange} />
      );
    case "Textarea":
      return (
        <Textarea field={field} value={value} onChange={handleInputChange} />
      );
    case "Lookup":
      return (
        <AutocompleteJSON
          field={field}
          fieldName={field.name}
          values={{ ...prefilledValues, owner_id: general.userId }}
          onChange={(fieldname, value) => handleInputChange(field.name, value)}
          value={value}
        />
      );
    case "MultipleLookup":
      return (
        <AutocompleteMultipleLookup
          field={field}
          fieldName={field.name}
          value={value}
          values={prefilledValues}
          onChange={(fieldname, value) => handleInputChange(field.name, value)}
        />
      );
    case "MasterDetail":
      return (
        <AutocompleteJSON
          field={field}
          fieldName={field.name}
          values={{ ...prefilledValues, owner_id: general.userId }}
          onChange={(fieldname, value) => handleInputChange(field.name, value)}
          value={value}
        />
      );
    case "Select":
      return (
        <SelectCST
          field={field}
          fieldName={field.fieldName}
          values={{ owner_id: general.userId }}
          onChange={(fieldname, value) => handleInputChange(field.name, value)}
          value={value}
          {...(prefilledValues && field.name in prefilledValues
            ? { value: prefilledValues[field.name] }
            : { owner_id: general.userId })}
        />
      );
    case "LocalizationInputs":
      return (
        <LocalizationInputs
          field={field}
          value={value}
          onChange={handleInputChange}
        />
      );
    case "Checkbox":
      return (
        <CheckboxCST field={field} value={value} onChange={handleInputChange} />
      );
    case "File":
      return <File field={field} value={value} onChange={handleInputChange} />;
    case "Photo":
      return <File field={field} value={value} onChange={handleInputChange} />;
    default:
      return null;
  }
};

export default FieldsRenderer;

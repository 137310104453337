import React, { useState } from "react";
import {
  Grid,
  Link,
  Typography,
  IconButton,
  Paper,
  Box,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DropzoneAreaExample from "components/dropzone/DropzoneArea";
import axios from "axios";
import { Close as CloseIcon } from "@mui/icons-material";
import toast from "react-hot-toast";

const FileMasterDetail = ({ masterDetails, fetchFormData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const recordsPerPage = 8;
  const pageCount = Math.ceil(
    masterDetails.relatedRecords.length / recordsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = masterDetails.relatedRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const handleDeleteFile = async () => {
    if (!selectedFile) return;

    try {
      console.log("Deleting file with ID:", selectedFile.id);
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      await axios.delete(`${endpoint}api/auth/delete/file`, {
        headers,
        data: {
          ids: [selectedFile.id],
        },
      });

      // Ricarica i dati dopo l'eliminazione
      fetchFormData();
      toast.success("File eliminato con successo!");
      setOpenDialog(false);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "Errore durante l'eliminazione del file.";
      toast.error(errorMessage);
    }
  };

  const handleOpenDialog = (file) => {
    setSelectedFile(file);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  return (
    <React.Fragment sx={{ padding: 0, display: "block" }}>
      <DropzoneAreaExample
        onUpload={fetchFormData}
        relationId={masterDetails.relationId}
      />

      {currentRecords.length > 0 ? (
        <Grid container spacing={0} sx={{ paddingLeft: 1 }}>
          {currentRecords.map((masterDetail) => {
            let fileTitle = "Titolo non disponibile";
            Object.entries(masterDetail.relatedValues).forEach(
              ([fieldKey, fieldValue]) => {
                if (fieldKey === "Nome File") {
                  fileTitle = fieldValue;
                }
              }
            );

            return (
              <Grid item xs={12} md={4} key={masterDetail.id}>
                <Paper
                  sx={{
                    boxShadow: 0,
                    padding: 0,
                    marginBottom: 0,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "top",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {Object.entries(masterDetail.relatedValues).map(
                    ([fieldKey, fieldValue]) => {
                      if (
                        fieldKey === "Proprietario" ||
                        fieldKey === "customRelations" ||
                        fieldKey === "ID" ||
                        fieldKey === masterDetails.fieldLabel ||
                        !fieldValue
                      ) {
                        return null;
                      }

                      if (fieldKey.toLowerCase() === "file") {
                        return (
                          <React.Fragment
                            key={`${masterDetail.id}-${fieldKey}`}
                          >
                            <Link
                              href={fieldValue}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                justifyContent="center"
                                sx={{
                                  "&:hover": {
                                    ".icon": {
                                      color: "blue",
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                    ".file-name": {
                                      textDecoration: "underline",
                                    },
                                  },
                                  background: `url(${fieldValue})`,
                                  height: 100,
                                  backgroundSize: "cover",
                                  position: "relative",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  component="p"
                                  className="file-name"
                                  sx={{
                                    fontSize: "0.75rem",
                                    mt: 1,
                                    transition: "all 0.3s ease",
                                  }}
                                ></Typography>
                              </Box>
                            </Link>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleOpenDialog(masterDetail)}
                              sx={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                zIndex: 10,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </React.Fragment>
                        );
                      }
                    }
                  )}
                </Paper>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Pagination
              count={pageCount}
              size="small"
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              sx={{ mt: 2, display: "flex", justifyContent: "left" }}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography>
          <Typography
            sx={{ px: 2, fontSize: 12, color: "gray", textAlign: "center" }}
          >
            Non sono presenti {masterDetails.objectName}.
          </Typography>
        </Typography>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma Eliminazione"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler eliminare il file{" "}
            {selectedFile
              ? selectedFile.relatedValues["Nome File"] || selectedFile.id
              : ""}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteFile} color="primary" autoFocus>
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FileMasterDetail;

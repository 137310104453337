import React, { useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/system";

// Styled component to handle border style based on fieldErrors
const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, fieldErrors }) => ({
    "& .MuiCheckbox-root": {
      border: fieldErrors ? "2px solid red" : "",
    },
  })
);

const CheckboxCST = ({ field, value, onChange, fieldErrors }) => {
  const handleCheckboxChange = (e) => {
    onChange(field.fieldName, e.target.checked);
  };

  // Cast the value to a boolean
  const isChecked = value === "1" || value === 1 || value === true;

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          {...(isChecked ? { checked: true } : {})}
          onChange={handleCheckboxChange}
          name={field.fieldName}
        />
      }
      label={field.label}
      fieldErrors={fieldErrors}
    />
  );
};

export default CheckboxCST;

import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { TextField } from "@mui/material";

const AutocompleteFakeMultiple = ({ field, fieldName, onChange, value }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getRecords/${field.relatedObject}`;
        const response = await axios.get(endpoint, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });

        console.log("API response:", response);

        if (Array.isArray(response.data)) {
          const extractedOptions = response.data.map((item) => ({
            id: item.id,
            label:
              field.relatedObject === "User"
                ? `${item.name} (${item.email}) - ${item.temporaryRole}`
                : item.title,
          }));
          setOptions(extractedOptions);

          // Converti value in array se è una stringa JSON
          const parsedValue = Array.isArray(value)
            ? value
            : JSON.parse(value || "[]");

          if (parsedValue.length > 0) {
            const selected = extractedOptions.filter((option) =>
              parsedValue.includes(option.id)
            );

            // Ordina selectedOptions in base all'ordine di parsedValue
            const orderedSelected = parsedValue
              .map((id) => selected.find((option) => option.id === id))
              .filter(Boolean); // Rimuove eventuali undefined

            setSelectedOptions(orderedSelected);
          }
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, [field, value]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);
    // Passa un array di ID al componente genitore
    const newValueIds = newValue.map((option) => option.id);
    onChange(fieldName, newValueIds);
  };

  return (
    <Autocomplete
      sx={{ height: "50px" }}
      multiple
      options={options}
      disabled
      label=""
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: null, // Rimuove l'icona di espansione
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          // Renderizza i tag senza il pulsante di cancellazione
          <Chip
            key={option.id}
            label={option.label}
            {...getTagProps({ index })}
            onDelete={null} // Imposta onDelete a null per rimuovere la X
          />
        ))
      }
    />
  );
};

export default AutocompleteFakeMultiple;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { TextField, IconButton } from "@mui/material"; // Import TextField from '@mui/material'
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import VisibilityIcon from "@mui/icons-material/Visibility";
// NumericFormat for Number based formatting like currency inputs.
import { NumericFormat } from "react-number-format";

const Float = ({ field, fieldName, values, fieldErrors, value, onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const fieldRef = useRef(null);

  useEffect(() => {
    // Set the initial value of the field
    if (values && values[field.fieldName] !== undefined) {
      setInputValue(values[field.fieldName]);
    }

    if (value !== undefined) {
      setInputValue(value);
    }
  }, [values, fieldName]);

  const handleValueChange = (values) => {
    setInputValue(values.value);
    onChange(field.fieldName, values.value);
  };

  useEffect(() => {
    if (fieldErrors && fieldRef.current) {
      fieldRef.current.querySelector("input").style.border = "2px solid red";
    }
  }, [fieldErrors]);

  return (
    <NumericFormat
      customInput={TextField} // Use TextField from Material-UI
      type="text"
      id={fieldName}
      sx={{ height: "20px" }}
      name={fieldName}
      value={inputValue}
      onValueChange={handleValueChange}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      onFocus={(e) => e.target.select()}
      margin="normal"
      fullWidth
      helperText={fieldErrors || ""}
      inputRef={fieldRef}
    />
  );
};

export default Float;

import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { TextField } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AutocompleteMultipleLookup = ({
  field,
  fieldName,
  formType,
  isCreate,
  isEdit,
  isShow,
  values,
  fieldErrors,
  onChange,
  value,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const fieldRef = useRef(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const endpoint =
          process.env.REACT_APP_APP_ENDPOINT +
          `api/auth/getRecords/${field.relatedObject}`;
        const response = await axios.get(endpoint, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        console.log("API response:", response);
        if (Array.isArray(response.data)) {
          let extractedOptions = response.data.map((item) => ({
            id: item.id,
            label:
              field.relatedObject === "User"
                ? `${item.name} (${item.email}) - ${item.temporaryRole}`
                : item.title,
          }));

          setOptions(extractedOptions);

          // Converti value e values[fieldName] in array se sono stringhe JSON
          let parsedValue = Array.isArray(value)
            ? value
            : JSON.parse(value || "[]");

          // Qui gestisci il valore iniziale (o i valori) se presente
          if (parsedValue.length > 0) {
            const selected = extractedOptions.filter((option) =>
              parsedValue.includes(option.id)
            );

            // Ordina selectedOptions in base all'ordine di parsedValue
            const orderedSelected = parsedValue
              .map((id) => selected.find((option) => option.id === id))
              .filter(Boolean); // Rimuove eventuali undefined

            setSelectedOptions(orderedSelected);
          } else if (values && values[fieldName]) {
            const parsedFieldValues = Array.isArray(values[fieldName])
              ? values[fieldName]
              : JSON.parse(values[fieldName] || "[]");

            const selected = extractedOptions.filter((option) =>
              parsedFieldValues.includes(option.id)
            );

            // Ordina selectedOptions in base all'ordine di parsedFieldValues
            const orderedSelected = parsedFieldValues
              .map((id) => selected.find((option) => option.id === id))
              .filter(Boolean); // Rimuove eventuali undefined

            setSelectedOptions(orderedSelected);
          }
        }
      } catch (error) {
        console.error("Errore nella chiamata all'endpoint:", error);
      }
    };

    fetchOptions();
  }, [field, value, values, fieldName]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);

    // Converti l'array di id in una stringa JSON
    const newValueIdsString = JSON.stringify(newValue.map((item) => item.id));

    // Qui passi la stringa JSON al metodo onChange
    onChange(field.name, newValueIdsString);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(selectedOptions);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setSelectedOptions(reorderedItems);
    onChange(field.name, JSON.stringify(reorderedItems.map((item) => item.id)));
  };

  return (
    <Autocomplete
      sx={{ height: "50px" }}
      ref={fieldRef}
      multiple={true} // Modifica qui per abilitare la selezione multipla
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleSelectionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Clicca per cercare"
          variant="standard"
          error={Boolean(fieldErrors)}
          helperText={fieldErrors || ""}
        />
      )}
      renderTags={(tagValue, getTagProps) => (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tags" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {tagValue.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <Chip
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        label={option.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: 2,
                          ...provided.draggableProps.style,
                        }}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    />
  );
};

export default AutocompleteMultipleLookup;
